
.thumbnail-container {
    position: relative;
    width: 100%;
    height: 190px;
}

.thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
}
